import { createApp } from "vue";
import { createMetaManager } from "vue-meta";
import App from "./App.vue";
import router from "./router";
import { store, key } from "./store";

// @ts-ignore
import VueGtag from "vue-gtag";

let gtag_options = {
  appName : "Location-Gardemeuble",
  bootstrap : false,
  deferScriptLoad: true,
  config  : {
    id : "G-FH09GQ19SQ"
  }
};

const app = createApp( App ).use( store, key ).use( router ).use( VueGtag, gtag_options, router ).use( createMetaManager() );
// await router.isReady();
app.mount( "#app" );
